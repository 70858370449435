import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Form, message, Spin, Space } from 'antd';
// import liff from '@line/liff';
import Cookies from 'js-cookie';

import PageFooter from 'components/PageFooter';
import iconLogout from 'assets/images/update/logout.svg';
import { loadProfile } from 'api/profile';
import * as Sentry from '@sentry/react';
import { Spinner } from 'components/UI/Spinner';

export default function StartScreen() {
  const { t } = useTranslation();
  const history = useHistory();
  const [user, setUser] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const redirectInfo = () => {
    history.push('/info');
  };

  const logout = () => {
    history.push('/');
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    localStorage.removeItem('lineId');
    // liff.logout();
  };

  const redirectTerm = () => {
    window.open('https://www.kampomania.com/terms', '_blank');
  };

  const redirectPrivacy = () => {
    window.open('https://www.kampomania.com/privacy', '_blank');
  };

  const redirectHistory = () => {
    history.push('/chart');
  };
  useEffect(() => {
    (async () => {
      window?.document?.getElementById('scroll')?.scrollIntoView();
      try {
        const response = await loadProfile();
        if (response.status == 'OK') {
          setUser(response?.body);
          if (response?.body?.anonymous) {
            window.location.href = '/';
            Cookies.remove('token');
            Cookies.remove('refreshToken');
            localStorage.removeItem('lineId');
            // liff.logout();
          }
          if (!response?.body?.anonymous) {
            setIsLoading(false);
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        /*
        console.log("response", error?.response)
        message.error(error?.response?.data?.error?.message);
        */
      }
    })();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className={styles.minHeight}>
          <Space size="middle">
            <Spinner />
          </Space>
        </div>
      ) : (
        <div className={styles.coverBacground}>
          <div className={styles.coverTitle}>
            <div className={styles.abc}>
              <div className={styles.textTitleHabit}>
                <div className={styles.coverTitleHabitScreen}>
                  <div className={styles.titleHabitScreen}>{t('startScreen.titleHabit1')}</div>
                  <div className={styles.titleHabitScreen}>{t('startScreen.titleHabit2')}</div>
                  <div className={styles.titleHabitScreen}>{t('startScreen.titleHabit3')}</div>
                </div>
              </div>
            </div>
            <div className={styles.textTitleMyFine}>
              {/* <div className={styles.titleMyFine}>{t('startScreen.titleMyFine1')}</div> */}
              <div className={styles.titleMyFine}>{t('startScreen.titleMyFine2')}</div>
            </div>
          </div>
          <div className={styles.coverQuestion}>
            <div className={styles.headProcess}>
              <div className={`${styles.processItem} ${styles.itemPart1}`}>
                <div className={styles.coverStepItem1}>
                  <div className={styles.titleStart}>{t('startScreen.titlePart1')}</div>
                  <div className={styles.basicStart}>{t('startScreen.basicInfo')}</div>
                </div>
              </div>
              <div className={`${styles.processItem} ${styles.itemPart2}`}>
                <div className={styles.coverStepItem1}>
                  <div className={styles.titleStart}>{t('startScreen.titlePart2')}</div>
                  <div className={styles.basicStart}>{t('startScreen.proofCheck')}</div>
                </div>
              </div>
              {/* <div className={`${styles.processItem} ${styles.itemPart3}`}>
    <div className={styles.coverStepItem}>
      <div className={styles.titleStart}> {t('startScreen.titlePart3')}</div>
      <div className={styles.basicStart}>{t('startScreen.symptomatologyCheck')}</div>
    </div>
  </div> */}
              <div className={`${styles.processItem} ${styles.itemPart3}`}>
                <div className={styles.coverStepItem1}>
                  <div className={styles.titleStart}>{t('startScreen.titlePart4')}</div>
                  <div className={styles.basicStart}>{t('startScreen.diagnosis')}</div>
                </div>
              </div>
            </div>
            <div className={styles.coverNotiPart}>
              <div className={styles.itemNotiPart}>{t('startScreen.itemNotiPart1')}</div>
              {/* <div className={styles.itemNotiPart}>{t('startScreen.itemNotiPart2')}</div> */}
            </div>
            <div className={styles.agreeTerm}>
              <div className={styles.decorationLine} onClick={redirectTerm}>
                {t('startScreen.agreeTerm1')}
              </div>
              <div>{t('startScreen.agreeTerm2')}</div>
              <div className={styles.decorationLine} onClick={redirectPrivacy}>
                {t('startScreen.agreeTerm3')}
              </div>
              <div>{t('startScreen.agreeTerm4')}</div>
            </div>
            <div className={styles.agreeTermAfter}>
              <div>{t('startScreen.agreeTerm5')}</div>
            </div>
            <div className={styles.loginContainer}>
              <Form>
                <div className={classNames(styles.coverButtonNext, styles.coverBtnUpdate)}>
                  <Button htmlType="button" onClick={redirectInfo}>
                    <div className={styles.coverBtnNext}>
                      <div className={styles.titleNext}>{t('login.updateStart')}</div>
                    </div>
                  </Button>
                </div>
              </Form>
            </div>
            <div className={styles.coverHistory} onClick={redirectHistory}>
              <div className={styles.titleHistory}>マイカルテ</div>
            </div>
            <div className={styles.contentNoti}>
              <div onClick={logout} className={styles.coverNotiUpdateBtn}>
                <div className={classNames(styles.itemContentNotiUpdate, styles.itemContentNoti)}>
                  {/* <img src={iconLogout} alt="" className={styles.cursor} /> */}
                  <span>
                    {user?.display_name}
                    {t('startScreen.itemNotiPart5')}
                  </span>
                </div>
              </div>

              <div className={classNames(styles.itemContentNoti, styles.itemMargin)}>
                {t('startScreen.itemNotiPart3')}
              </div>
              <div className={styles.itemContentNoti}>{t('startScreen.itemNotiPart4')}</div>
            </div>
            <div className={styles.coverNotification}>
              <div className={styles.titleNotification}>{t('startScreen.aboutService1')}</div>
              <div className={styles.itemContentNotification}>{t('startScreen.aboutService2')}</div>
              <div className={styles.itemContentNotification}>{t('startScreen.aboutService3')}</div>
              <div className={styles.itemContentNotification}> {t('startScreen.aboutService4')}</div>
              <div className={styles.itemContentNotification}>{t('startScreen.aboutService5')}</div>
              {/* <div className={styles.itemContentNotification}>{t('startScreen.aboutService6')}</div> */}
            </div>
          </div>
          <div className={styles.coverFooter}>
            <PageFooter />
          </div>
        </div>
      )}
    </>
  );
}
