import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export default function PageFooter() {
  const { t } = useTranslation();
  const history = useHistory();

  const redirectVarytex = () => {
    window.open("https://www.varytex.co.jp/", "_blank")
  }

  const redirectTerm = () => {
    window.open("https://www.kampomania.com/terms", "_blank")
  }

  const redirectPrivacy = () => {
    window.open("https://www.kampomania.com/privacy", "_blank")
  }

  const redirectLaw = () => {
    window.open("https://www.kampomania.com/law", "_blank")
  }
  
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.heightFooter}>
        <div className={styles.heightFooter7}></div>
        <div className={styles.heightFooter1} onClick={redirectPrivacy}>{t('footer.title1')}</div>
        <hr />
        <div className={styles.heightFooter1} onClick={redirectTerm}>{t('footer.title2')}</div>
        <hr />
        <div className={styles.heightFooter1} onClick={redirectLaw}>{t('footer.title3')}</div>
        <hr />
        <div className={styles.heightFooter5} onClick={redirectVarytex}>{t('footer.title4')}</div>
        <div className={styles.heightFooter6}>{t('footer.title6')}</div>
      </div>
    </div>
  );
}
