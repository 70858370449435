import React, { lazy, Suspense, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import PageHeader from 'components/PageHeader';
import SideNav from 'components/SideNav';
import { Redirect, Route, Switch } from 'react-router-dom';
import styles from './styles.module.scss';
import { useQuery } from 'react-query';
import { loadProfile } from 'api/profile';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import ReactGA from "react-ga4";
import configs from 'config';
import HomePage from 'pages/homepage';

const Info = lazy(() => import('pages/Info'));
const Step1 = lazy(() => import('pages/Step1'));
const Step2 = lazy(() => import('pages/Step2'));
const Step3 = lazy(() => import('pages/Step3'));
const Step4 = lazy(() => import('pages/Step4'));
const Step5 = lazy(() => import('pages/Step5'));
const Step6 = lazy(() => import('pages/Step6'));

const Result = lazy(() => import('pages/Result'));
const ResultLogin = lazy(() => import('pages/ResultLogin'));

export default function PageWrapper() {
  const params = useLocation();
  const [checkPath, setCheckPath] = useState<boolean>(false);
  const pathGA: any = params?.pathname.split('/')

  useEffect(() => {
    if (params?.pathname === '/home') {
      setCheckPath(true);
    } else {
      setCheckPath(false);
      ReactGA.event({
        category: `System_authwrapper`,
        action: `System_authwrapper}`,
      });
    }

    // ReactGA.event({
    //   category: `System_page_view_${params.pathname}`,
    //   action: `System_page_view_${params.pathname}`,
    // });
    // window.gtag('config', `${configs.GA}`);
    // window.gtag('event', 'page_view', {
    //   page_title: `System_page_view_${pathGA[1]}`,
    //   page_location: `System_page_view_${pathGA[1]}`,
    //   page_path: `System_page_view_${pathGA[1]}`,
    //   send_to: `${configs.GA}`,
    // });
  }, [params]);

  const isAuthenticated = !!Cookies.get('token');
  // const { data: profile } = useQuery('profile', loadProfile, { enabled: isAuthenticated });
  if (!isAuthenticated) return <Redirect to={`/start-screen?redirect_url=${params?.pathname}`} />;

  // window.onscroll = function (e) {
  //   ReactGA.event({
  //     category: `System_scroll_to_view_${pathGA[1]}`,
  //     action: `System_scroll_to_view_${pathGA[1]}`,
  //   });
  // }
  // if (!profile) return null;
  return (
    <>
      {/* {!checkPath ? <PageHeader /> : null} */}
      <div className={styles.pageWrapper}>
        <div className={styles.mainWrapper}>
          <div className={classNames(styles.pageContent, checkPath ? styles.coverPage : '')} id="scroll">
            <Suspense fallback={null}>
              <Switch>
              <Route exact path="/info" component={Info} />
                <Route exact path="/step-1" component={Step1} />
                <Route exact path="/step-2" component={Step2} />
                <Route exact path="/step-3" component={Step3} />
                <Route exact path="/step-4" component={Step4} />
                <Route exact path="/step-5" component={Step5} />
                <Route exact path="/step-6/:id" component={Step6} />
                <Route exact path="/results" component={Result} />
                <Route exact path="/home" component={HomePage} />
              </Switch>
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}
